@import 'styles/variables';

.footer-join {
  text-align: center;
  padding: 40px 32px 40px;
  margin-bottom: 40px;

  :global .footer-join__title {
    margin: 0;
    font-size: 24px;

    @media #{$breakpoint-md-up} {
      font-size: 24px;
      line-height: 28px;
    }

    @media #{$breakpoint-lg-up} {
      font-size: 28px;
      line-height: 36px;
    }
  }

  :global .footer-join__subtitle {
    font-weight: 400;
  }

  :global .footer-join__form {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
  }

  :global .footer-join__input {
    .form-input__input {
      border-radius: 32px;
    }

    @media #{$breakpoint-sm-up} {
      .form-input__input {
        min-height: 60px !important;
        padding-right: 120px;
        border-radius: 32px;
        padding-left: 26px;
      }

      .form-input__label--placeholder {
        left: 30px !important;
      }

      .form-input__label--placeholder-filled {
        left: 22px !important;
      }

      .form-input--error .form-input__input:focus + .form-input__label--placeholder {
        left: 24px !important;
      }
    }
  }

  :global .footer-join__button {
    width: 100%;

    @media #{$breakpoint-sm-up} {
      position: absolute;
      width: unset;
      top: 6px;
      right: 6px;
      padding-left: 28px;
      padding-right: 28px;
    }
  }

  @media #{$breakpoint-md-up} {
    padding: 60px 30px;
    margin-bottom: 0;
  }
}
