@import 'styles/variables';
@import 'styles/shadows';
@import '../Header.module';

.header-user {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 0;

  @media #{$breakpoint-sm-up} {
    grid-gap: 0px;
    margin-right: 0;
  }

  :global .header-user__profile {
    position: relative;
  }

  :global .header-user__avatar {
    width: 46px !important;
    height: 46px !important;
    margin-right: 12px;
  }

  :global .header-user__badge {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    background: $gradient-buttons-linear;
    color: $color-white;
    font-size: 9px;
    line-height: 18px;
    text-align: center;
  }

  :global .header-user__toggle {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    color: $color-primary-purple;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    margin-right: -6px;

    &--dropped {
      .header-user__toggle-icon {
        transform: scale(-1, -1);
      }
    }

    &:focus {
      outline: none;
    }
  }

  :global .header-user__menu {
    @include shadow-cards;

    position: absolute;
    visibility: hidden;
    display: none;
    list-style: none;
    width: calc(100vw - 48px);
    right: 14px;
    top: 48px;
    margin: 0;
    padding: 24px;
    background-color: $color-white;
    border-radius: 8px;
    z-index: 10;

    .header__nav-button {
      @include header-dropdown-button;

      text-align: left;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      padding: 12px 24px !important;

      &:hover {
        text-decoration: none !important;
      }

      &--logout {
        color: $color-light-grey;
        cursor: pointer;
      }

      &--my-trips {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &--dropped {
      visibility: visible;
      display: block;
      opacity: 1;
    }

    @media #{$breakpoint-sm-up} {
      width: 260px;
      right: 40px;
    }
  }

  :global .user-controls__request-button {
    margin-right: 8px;

  }

  :global .header__request-button {
    display: none;

    @media #{$breakpoint-sm-up} {
      display: inline-block;
      display: flex;
      align-items: center;
    }
  }

  :global .header-user__separator {
    border-width: 1px 0 0;
    border-style: solid;
    border-color: $color-light-craft;
    margin: 15px 0;
  }
}
