@import 'styles/variables';

.footer-partners {
  max-width: 960px;
  padding: 0;
  margin: 0 auto 48px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  :global .footer-partners__item {
    margin: 0 10px 20px !important;
    max-width: 80px;

    @media #{$breakpoint-md-up} {
      padding: 0 30px;
      justify-content: space-around;
      margin: 0 auto 70px;
    }

    @media #{$breakpoint-md-up} {
      max-width: unset;
    }
  }
}
