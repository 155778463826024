.header-badge {
  > span {
    height: 14px;
    padding: 8px 6px;
    font-weight: 600;
    min-width: 14px;
    top: 8px;
    right: 12px;
  }
}
