@import 'styles/variables';
@import 'styles/shadows';
@import 'styles/typography';
@import 'styles/cards';

/*
  There's a lot of bicycle code,
  the customer decided to play in perfect pixel
  related to their own landing page
*/

@mixin header-dropdown-button {
  display: block;
  padding: 5px 15px;
  border-radius: 32px;
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
  color: $color-black;
  transition: 0.1s linear all;

  &:hover {
    color: $color-primary-purple;
    background-color: $color-light-purple;
    text-decoration: underline;
  }
}

.header {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background-color: $color-white;
  z-index: 1000;

  :global .show-header-shadow {
    .noInitialShadow {
      border-bottom: 1px solid #5c576626 !important;
      box-shadow: 0px 2px 10px #5c576614 !important;
      transition: all 0.2s ease-in-out;
    }
  }

  :global .header__container {
    box-shadow: 0px 2px 10px #5c576614;
    border-bottom: 1px solid #5c576626;
    height: 64px;

    .header__container-inner {
      display: flex;
      position: relative;
      align-items: center;
      padding: 0 16px;
      height: 64px;

      @media #{$breakpoint-lg-up} {
        height: 80px;
      }

      @media #{$breakpoint-xl-up} {
        height: 82px;
      }
    }

    &.smallContainer {
      .header__container-inner {
        @media #{$breakpoint-sm-up} {
          padding: 0;
          max-width: 1120px;
          margin: 0 auto;
          padding: 0 24px;
        }
      }
    }

    @media #{$breakpoint-sm-up} {
      &.noInitialShadow {
        border-bottom: 1px solid #5c576600;
        box-shadow: 0px 2px 10px #5c576600;
        transition: all 0.2s ease-in-out;
      }
    }

    @media #{$breakpoint-lg-up} {
      padding-left: 32px;
      padding-right: 32px;
      height: 80px;
    }

    @media #{$webflow-lg-breakpoint} {
      padding-left: 72px;
      padding-right: 72px;
    }

    @media #{$webflow-xl-breakpoint} {
      padding-left: 98px;
      padding-right: 98px;
    }
  }

  :global .header__body--open {
    overflow: hidden;

    @media #{$breakpoint-lg-up} {
      overflow: auto;
    }
  }

  :global .header__controls {
    margin-left: auto;

    @media #{$breakpoint-lg-up} {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      grid-gap: 7px;
    }
  }

  :global .header__logo {
    margin-left: auto;
    margin-right: auto;
    width: 120px;
    position: absolute;
    top: calc(50% + 3px);
    left: calc(50%);
    transform: translate(-50%, -50%);

    @media #{$breakpoint-lg-up} {
      width: 140px;
      height: unset;
    }

    &--offset {
      left: calc(30% + 7px);
      @media #{$breakpoint-sm-up} {
        left: calc(50%);
      }
    }
  }

  :global .header__nav {
    @include default-card;

    margin: 0;
    display: block;
    padding: 24px;
    border-radius: 8px;
    position: absolute;
    top: 86px;
    left: 24px;
    width: calc(100% - 48px);
    z-index: 100;
    transition: 0.2s linear all;
    background-color: $color-white;

    &--closed {
      display: none;
    }

    &-item {
      display: flex;

      .header__dropdown {
        margin-right: 18px;
      }
    }

    &-button {
      display: block;
      width: 100%;
      padding: 28px 8px 28px 10px;
      margin-right: 4px;
      font-weight: 600;
      color: $color-light-dark;
      font-size: 14px;
      text-decoration: none;
      transition: 0.2s linear all;
      text-align: center;
      position: relative;

      &-menu-arrow {
        position: absolute;
        display: inline-block;
        top: calc(50% - 1px);
        transform: translateY(-50%);
        right: -5px;
        margin-left: 0;

        img {
          width: 8px !important;
        }

        @media #{$webflow-lg-breakpoint} {
          right: 27px;
          top: calc(50% - 2px);
        }
      }

      &--active {
        text-decoration: underline;
      }

      &:hover {
        text-decoration: underline;
        color: $color-light-dark !important;
      }

      &:first-child {
        @media #{$webflow-lg-breakpoint} {
          padding-left: 0;
        }
      }

      @media #{$webflow-lg-breakpoint} {
        font-size: 1em; // 16px;
        padding-top: 27px;
        padding-left: 24px;
        padding-right: 40px;
      }
    }

    &--open {
      @media screen and (max-width: $md-max) {
        display: block;
        overflow: auto;
        background-color: $color-white;
      }
    }

    @media #{$breakpoint-lg-up} {
      width: unset;
      position: initial;
      display: grid;
      grid-auto-flow: column;
      padding: 0;
      transition: 0s linear all;
      box-shadow: none;
    }
  }

  :global .header__button {
    background-color: transparent;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;

    &-icon {
      color: $color-secondary-blue;
      fill: $color-secondary-blue;
    }

    &:focus {
      outline: none;
    }
  }

  :global .header__dropdown {
    position: relative;
    cursor: pointer;
    width: 100%;

    &-list {
      margin: 0;
      padding: 0;
      width: 100%;

      @media #{$breakpoint-lg-up} {
        position: absolute;
        top: 64px;
        left: 0;
        background-color: #f4f2ff;
        border-radius: 10px;
        padding: 10px 5px;
        width: 155px;
        visibility: hidden;

        .header__nav-button {
          @include header-dropdown-button;
        }
      }
    }

    .header__nav-button--active {
      color: #0e5aff;

      &:hover {
        color: #0e5aff !important;
      }
    }

    &:hover {
      .header__dropdown-list {
        visibility: visible;
      }
    }
  }

  :global .header__request-button {
    @media #{$breakpoint-lg-up} {
      background-image: linear-gradient(45deg, #324bf9 11%, #9409ee 84%);
      padding-top: 7px !important;
      padding-bottom: 7px !important;

      .btn__inner {
        line-height: 23px;
      }
    }

    @media #{$webflow-lg-breakpoint} {
      padding-top: 8px !important;
      padding-bottom: 6px !important;
    }
  }
}
