@import 'styles/variables';

.app-footer {
  :global .app-footer--customer-area {
    .footer-socials {
      grid-template-columns: repeat(5, 1fr);
      margin-bottom: 0;
    }
  }

  :global footer {
    padding-top: 40px;
    background: #faf9fb;

    @media #{$breakpoint-md-up} {
      padding-top: 56px;
    }
  }

  :global .col {
    &-40 {
      width: 100%;
    }

    &-20 {
      width: 50%;

      &.mt-mobile {
        margin-top: 40px;
      }
    }

    @media #{$breakpoint-md-up} {
      &-40 {
        width: 40%;
      }

      &-20 {
        width: 20%;

        &.mt-mobile {
          margin-top: 0;
        }
      }
    }
  }

  :global .footer {
    &__col {
      &--left {
        display: flex;
        justify-content: space-between;

        @media #{$breakpoint-lg-up} {
          display: block;
        }
      }

      &--right {

        .col-sm-3,
        .col-sm-6 {
          padding: 0;
        }
      }
    }

    &__logo {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media #{$breakpoint-md-up} {
        align-items: flex-start;
      }
    }

    &__badges-mobile {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      padding-bottom: 32px;
      border-bottom: 1px solid $color-gray-3;

      @media #{$breakpoint-md-up} {
        display: none;
      }

      .footer__cert {
        margin-left: 8px;
      }

      .footer__covid {
        margin-right: 8px;
      }
    }

    &__badges {
      display: none;

      @media #{$breakpoint-md-up} {
        display: flex;
        justify-content: flex-end;
      }

      .footer__cert,
      .footer__covid {
        display: inline-block !important;
        vertical-align: top;
      }

      .footer__covid {
        margin-right: 16px;
      }
    }

    &__covid {
      @media #{$breakpoint-sm-up} {
        width: 80px;
      }

      @media #{$breakpoint-md-up} {
        width: 85px;
      }
    }

    &__exclusive {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;

      .footer__category {
        margin-bottom: 8px;
        margin-right: 0;
      }

      @media #{$breakpoint-md-up} {
        align-items: flex-start;
        margin-right: 25px;
        margin-top: 0;
      }

      @media screen and (max-width: $md-max) {
        margin-bottom: 10px;
      }
    }

    &__category {
      color: $color-gray-7;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;

      @media screen and (max-width: $xs-max) {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
      }
    }

    &__b-corp {
      padding-left: 24px;
      margin-top: -10px;

      @media #{$webflow-lg-breakpoint} {
        margin-top: -15px;
      }
    }

    &__nav {
      color: $color-gray-7;
      text-decoration: none;

      &:hover {
        color: $color-gray-8;
      }

      &--exclusive {
        color: $color-black;
        font-weight: 600;
        display: inline-grid;
        grid-auto-flow: column;
        grid-gap: 26px;
        align-items: center;
        padding: 2px;
        margin-left: -14px;

        .btn__inner {
          padding-right: 45px;
          padding-left: 45px;

          @media screen and (max-width: 380px) {
            padding-right: 18px;
            padding-left: 18px;
          }
        }

        @media screen and (max-width: $md-max) {
          margin-left: 0;
        }
      }
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 8px 0;

      li {
        font-size: 14px;
        margin: 8px 0;
      }
    }

    &__group {
      &-title {
        font-weight: 600;
      }

      @media #{$breakpoint-md-up} {
        margin-bottom: 30px;
      }
    }

    &__destinations {
      margin-top: 32px;
      padding-top: 32px;
      padding-bottom: 48px;
      width: 100%;
      border-top: 1px solid $color-gray-3;

      :global .footer__group-title {
        color: $color-gray-7;
        font-size: 12px;
        margin-bottom: 4px;
      }

      &-list {
        columns: 2;

        li {
          font-size: 12px;
          margin-bottom: 4px;
          line-height: 16px;
        }

        @media #{$breakpoint-md-up} {
          columns: 5;
        }
      }
    }
  }
}

.footer_hiw {
  border: none;
  background-color: transparent;
  padding: 0;
  text-align: left;
}