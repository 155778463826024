@import 'styles/variables';

.footer-socials {
  width: fit-content;
  display: flex;
  grid-gap: 24px;
  margin-top: 40px;
  margin-bottom: 32px;

  @media #{$breakpoint-md-up} {
    margin-top: 32px;
  }

  :global .footer-socials__item {
    transition: 0.1s linear all;

    &:hover {
      opacity: 0.8;
    }
  }
}
